import {getCompatibleImageFileExtension} from '@Utils/image.util';
import {repoURL} from '@Libraries/s3-library';
import {GROWL_TYPE, showMessageGrowl} from '@Components/message-growl';
import {getWriteBucket} from '@Utils/s3.util';
import {getMaxAllowedUploadFileSize, getReadableMaxAllowedUploadFileSize} from '@Libraries/user-media-library';

export enum UserAudioSource {
  UPLOAD = 'upload',
  STORYBLOCKS = 'storyblocks',
  TEXT_TO_SPEECH = 'TEXT_TO_SPEECH',
}

const THUMBNAIL_EXTENSION = 'webp';
export const AUDIO_EXTENSION = 'mp3';
const THUMBNAIL_BUCKET = 'audiouploads/waveforms';
const AUDIO_BUCKET = 'audiouploads';

export interface UserAudioVOResponse {
  id: string;
  duration: number;
  uploaderName: string;
  uploaderId: string;
  ext: string;
  filename: string;
  name: string;
  src: UserAudioSource;
}

/**
 * Creates a URL to the image thumbnail of audio item
 * @param {String} hashedFilename
 * @returns {String} URL that returns the image
 */
export const repoAudioThumbImageURL = (hashedFilename: string): string => {
  return repoURL(`${THUMBNAIL_BUCKET}/${hashedFilename}_v2.${getCompatibleImageFileExtension(THUMBNAIL_EXTENSION)}`, getWriteBucket());
};

/**
 * @param hashedFilename
 * @param _source
 */
export const getAudioUrl = (hashedFilename: string, _source: UserAudioSource = UserAudioSource.UPLOAD): string => {
  return repoURL(`${AUDIO_BUCKET}/${hashedFilename}.${AUDIO_EXTENSION}`, getWriteBucket());
};

/**
 * Filters and returns video files that are valid and supported by us
 * @param {Array} files to upload
 * @return {Array} returns valid files
 * @override
 */
export const filterValidAudioFiles = (files: File[]): File[] => {
  let invalidFiles = 0;
  for (let i = files.length - 1; i >= 0; i--) {
    if (!isAudioFileValid(files[i]) || files[i].size > getMaxAllowedUploadFileSize()) {
      invalidFiles += 1;
      files.splice(i, 1);
    }
  }

  if (invalidFiles === 1) {
    showMessageGrowl({
      type: GROWL_TYPE.DANGER,
      text: window.i18next.t('pmwjs_upload_invalid_audio_file', {
        uploadFileSizeLimit: getReadableMaxAllowedUploadFileSize(),
      }),
    });
  } else if (invalidFiles > 1) {
    showMessageGrowl({
      type: GROWL_TYPE.DANGER,
      text: window.i18next.t('pmwjs_upload_all_invalid_audio_files', {
        numfiles: invalidFiles,
        uploadFileSizeLimit: getReadableMaxAllowedUploadFileSize(),
      }),
    });
  }
  return files;
};

export const isAudioFileValid = (file: File): boolean => {
  return !!file.type && file.type.includes('audio/');
};
