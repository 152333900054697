import {getCompatibleVideoFileExtension, isFiletypeVideo} from '@Utils/video.util';
import {getCompatibleImageFileExtension} from '@Utils/image.util';
import {repoURL} from '@Libraries/s3-library';
import type {VideoItem} from '@PosterWhiteboard/items/video-item/video-item.class';
import {GROWL_TYPE, showMessageGrowl} from '@Components/message-growl';
import {isProResSupported, isWebmSupported} from '@Utils/browser.util';
import {REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION} from '@Libraries/image-item.library';
import {roundPrecision, secondsToMicroseconds} from '@Utils/math.util';
import {getWriteBucket} from '@Utils/s3.util';
import {isUserPremium} from '@Libraries/user.library';
import {getMaxAllowedUploadFileSize, getReadableMaxAllowedUploadFileSize} from '@Libraries/user-media-library';

export enum UserVideoSource {
  GETTY = 'getty',
  EMAIL_UPLOAD = 'email_upload',
  UPLOAD = 'upload',
  PIXABAY = 'pixabay',
  STORYBLOCKS = 'storyblocks',
}

export enum UserVideoSizeType {
  HIGHRES = 'highres',
  SCREEN = 'screen',
  THUMB = 'thumb',
}

export interface UserVideoVOResponse {
  id: string;
  duration: number;
  frameRate: number;
  hasTransparency: boolean;
  imageExt: string;
  uploaderName: string;
  uploaderId: string;
  ext: string;
  filename: string;
  src: UserVideoSource;
  width: number;
  height: number;
}

const TRANSPARENT_VIDEO_FALLBACK_EXTENSION = 'mov';
const REMOVED_BG_VIDEO_EXTENSION = 'webm';
const VIDEO_IMAGE_THUMBNAIL = 'webp';
const LEGACY_VIDEO_IMAGE_THUMBNAIL = 'jpg';
const VIDEO_BUCKET = 'videouploads';
const SCREEN_SIZE_BUCKET = `${VIDEO_BUCKET}/screens`;
const THUMB_SIZE_BUCKET = `${VIDEO_BUCKET}/thumbs`;

/**
 * Creates a URL to the image of video thumb
 */
export const repoVideoThumbImageURL = (hashedFilename: string, videoFileExtension: string): string => {
  const imageFileExtension = videoFileExtension === 'webm' ? VIDEO_IMAGE_THUMBNAIL : LEGACY_VIDEO_IMAGE_THUMBNAIL;
  return repoURL(`${THUMB_SIZE_BUCKET}/${hashedFilename}.${getCompatibleImageFileExtension(imageFileExtension)}`, getWriteBucket());
};
/**
 * Creates a URL to thumb size video
 */
export const repoVideoThumbURL = (hashedFilename: string, videoFileExtension: string): string => {
  return repoURL(`${THUMB_SIZE_BUCKET}/${hashedFilename}.${getCompatibleVideoFileExtension(videoFileExtension)}`, getWriteBucket());
};

/**
 * Filters and returns video files that are valid and supported by us
 * @override
 */
export const filterValidVideoFiles = async (files: File[]): Promise<File[]> => {
  const filteredFiles = [];
  for (const file of files) {
    if ((await isVideoFileValid(file)) && file.size <= getMaxAllowedUploadFileSize()) {
      filteredFiles.push(file);
    }
  }
  const invalidFiles = files.length - filteredFiles.length;

  if (invalidFiles === 1) {
    showMessageGrowl({
      type: GROWL_TYPE.DANGER,
      text: window.i18next.t('pmwjs_upload_invalid_video_file', {
        uploadFileSizeLimit: getReadableMaxAllowedUploadFileSize(),
      }),
    });
  } else if (invalidFiles > 1) {
    showMessageGrowl({
      type: GROWL_TYPE.DANGER,
      text: window.i18next.t('pmwjs_upload_all_invalid_video_files', {
        numfiles: invalidFiles,
        uploadFileSizeLimit: getReadableMaxAllowedUploadFileSize(),
      }),
    });
  }
  return filteredFiles;
};

export const isVideoFileValid = async (file: File): Promise<boolean> => {
  return isFiletypeVideo(file.type) || ((await isFileValid(file)) as boolean);
};

/**
 * Whether the given file has valid data or not
 */
const isFileValid = (file: File) => {
  return new Promise((resolve): void => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e): void => {
      resolve(isFileDataValidVideo(e));
    };
    fileReader.onabort = fileReader.onerror = (): void => {
      resolve(false);
    };
    const blob = file.slice(0, 4);
    fileReader.readAsArrayBuffer(blob);
  });
};

/**
 * Determines whether the video has one of the known signatures of video formats or not
 * @param {Event} e
 * @returns {boolean}
 */
const isFileDataValidVideo = (e: ProgressEvent<FileReader>): boolean => {
  if (!e.target) {
    return false;
  }

  if (e.target.readyState === FileReader.DONE) {
    if (e.target.result === null || typeof e.target.result === 'string') {
      return false;
    }

    const uInt = new Uint8Array(e.target.result);
    const bytes: string[] = [];
    $.each(uInt, (key, value) => {
      bytes.push(value.toString(16));
    });
    const hex = bytes.join('').toUpperCase();

    // Check if the hex belongs to one of the known signatures of video formats
    return !(
      hex !== '1A45DFA3' &&
      hex !== '52494646' &&
      hex !== '41564920' &&
      hex !== '00000018' &&
      hex !== '00000014' &&
      hex !== '000001BA' &&
      hex !== '2E524543' &&
      hex !== '464C5601' &&
      hex !== '52494646' &&
      hex !== '47494638'
    );
  }
  return true;
};

export const getRepoVideoURL = (filename: string, isHighRes = false): string => {
  const type = isHighRes ? UserVideoSizeType.HIGHRES : UserVideoSizeType.SCREEN;
  const bucket = getBucketForVideoType(type);

  return repoURL(`${bucket}/${filename}`, getWriteBucket());
};

export const repoVideoURLForModel = (model: VideoItem, type?: UserVideoSizeType): string => {
  if (model.page.poster.mode.shouldOptimizeItemLoadForGeneration()) {
    return window.PMW.util.asset_url('videos/place-holder.webm');
  }

  let sizeType = type;
  if (sizeType === undefined) {
    sizeType = model.page?.poster?.isHighRes ? UserVideoSizeType.HIGHRES : UserVideoSizeType.SCREEN;
  }

  const bucket = getBucketForVideoType(sizeType);
  const filename = getFilenameForModel(model, sizeType === UserVideoSizeType.HIGHRES);

  return repoURL(`${bucket}/${filename}`, getWriteBucket());
};

const getBucketForVideoType = (type: UserVideoSizeType): string => {
  switch (type) {
    case UserVideoSizeType.HIGHRES:
      return VIDEO_BUCKET;

    case UserVideoSizeType.SCREEN:
      return SCREEN_SIZE_BUCKET;

    case UserVideoSizeType.THUMB:
      return THUMB_SIZE_BUCKET;

    default:
      throw new Error(`Invalid videoType: ${type}`);
  }
};

/**
 * Returns the filename for video to be loaded on the builder
 */
const getFilenameForModel = (model: VideoItem, isHighRes: boolean): string => {
  let videoFileName = model.hashedFilename;
  let extension = model.fileExtension;
  let {hasTransparency} = model;

  if (model.removeBackground.isBackgroundRemoved) {
    videoFileName = getRemovedBgFilenameForModel(model);
    extension = REMOVED_BG_VIDEO_EXTENSION;
    hasTransparency = true;
  } else {
    const getWatermarkFreeGettyVersion = model.hasGettyContent() && isUserPremium();

    if (getWatermarkFreeGettyVersion) {
      videoFileName += '_wf';
    }
  }

  return getFilenameForVideo(videoFileName, extension, isHighRes, hasTransparency);
};

/**
 * Load mp4 version of video if video doesn't have transparency as playing mp4 is less compute intensive then webm. For highRes
 * we weren't uploading a backup mp4 for webm so load the file with the same fileExtension as provided
 */
export const getFilenameForVideo = (hashedFileName: string, fileExtension: string, isHighRes: boolean, hasTransparency = false): string => {
  if (fileExtension === 'webm' && isWebmSupported()) {
    return getWebmFilename(hashedFileName);
  }

  if (shouldLoadProResVideo(fileExtension, hasTransparency)) {
    return getProResFilename(hashedFileName);
  }

  if (shouldLoadGreenScreenMp4(fileExtension, hasTransparency)) {
    return getGreenScreenMP4VideoFilename(hashedFileName);
  }

  return getMP4VideoFilename(hashedFileName);
};

export const shouldLoadGreenScreenMp4 = (fileExtension: string, hasTransparency: boolean): boolean => {
  return fileExtension === 'webm' && hasTransparency && !isWebmSupported() && !isProResSupported();
};

const shouldLoadProResVideo = (fileExtension: string, hasTransparency: boolean): boolean => {
  return fileExtension === 'webm' && hasTransparency && !isWebmSupported() && isProResSupported();
};

const getProResFilename = (hashedFilename: string): string => {
  return `${hashedFilename}.${TRANSPARENT_VIDEO_FALLBACK_EXTENSION}`;
};

const getWebmFilename = (hashedFilename: string): string => {
  return `${hashedFilename}.webm`;
};

const getMP4VideoFilename = (hashedFilename: string): string => {
  return `${hashedFilename}.mp4`;
};

const getGreenScreenMP4VideoFilename = (hashedFilename: string): string => {
  return `${hashedFilename}.mp4`;
};

export const getRemovedBgFilenameWithExtension = (hashedFilename: string, trimStart: number, trimEnd: number): string => {
  const trimStartTime = roundPrecision(trimStart, 6);
  const trimEndTime = roundPrecision(trimEnd, 6);
  const trimStartSubstring = Math.round(secondsToMicroseconds(trimStartTime));
  const trimEndSubstring = Math.round(secondsToMicroseconds(trimEndTime));

  return `${hashedFilename}${REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION}_${trimStartSubstring}_${trimEndSubstring}.${REMOVED_BG_VIDEO_EXTENSION}`;
};

const getRemovedBgFilenameForModel = (model: VideoItem): string => {
  const {trimData} = model.removeBackground;
  const trimStartTime = roundPrecision(trimData.startTime, 6);
  const trimEndTime = roundPrecision(trimData.endTime, 6);
  const trimStartSubstring = Math.round(secondsToMicroseconds(trimStartTime));
  const trimEndSubstring = Math.round(secondsToMicroseconds(trimEndTime));

  return `${model.hashedFilename}${REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION}_${trimStartSubstring}_${trimEndSubstring}`;
};
